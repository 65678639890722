import styles from "./MobileMenu.module.scss";
import Link from "next/link";
import * as React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OffsetMenu } from "../menu/OffsetMenu";
import { MenuContext } from "../context/AppContext";

type Props = {
  show: boolean;
  onHide: () => void;
};

export const MobileMenu = ({ show, onHide }: Props): JSX.Element => {
  const menu = React.useContext(MenuContext);

  return (
    <OffsetMenu show={show} onHide={onHide} type="bottom" title="Naršyk pagal kategoriją">
      <ul className={styles.menuItems}>
        {menu?.menus?.nodes
          ?.find((menu) => menu.name === "mobile")
          ?.menuItems.nodes.map((menuItem) => (
            <li key={menuItem.label}>
              {menuItem.path === "/-" ? (
                <span className={styles.menuItemTitle}>{menuItem.label}</span>
              ) : (
                <Link key={menuItem.label} href={menuItem.path} onClick={() => onHide()}>
                  {menuItem.label}
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              )}
            </li>
          ))}
      </ul>
    </OffsetMenu>
  );
};
