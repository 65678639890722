import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./Normal.module.scss";
import { HorizontalLogo } from "../logo/HorizontalLogo";
import classNames from "classnames";
import { useMenu } from "../hooks/useMenu";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { MobileMenu } from "./MobileMenu";
import { SIZES, useResponsive } from "../hooks/useResponsive";
import { AcceptCookies } from "../utils/AcceptCookies";
import { MetaType, TitleAndDescription } from "../Layout";
import { Toaster } from "react-hot-toast";
import { infoNotification } from "../utils/notifications";
import { FavoritesPopup } from "../favorite/FavoritesPopup";
import { UserPopup } from "../userLogin/UserPopup";
import { Loading } from "../loading/Loading";
import { TryOnPopup } from "../favorite/TryOnPopup";

type Props = {
  children?: React.ReactNode;
  sticky?: (sticky: boolean) => JSX.Element;
  notSticky?: boolean;
  menuLeft?: React.ReactNode;
  menuRight?: React.ReactNode;
};

export const LayoutMinimumWithHead = ({
  meta,
  children,
  sticky,
  notSticky,
  menuLeft,
  menuRight,
}: MetaType & Props): JSX.Element => {
  return (
    <>
      <TitleAndDescription meta={meta} />
      <LayoutMinimum sticky={sticky} notSticky={notSticky} menuLeft={menuLeft} menuRight={menuRight}>
        {children}
      </LayoutMinimum>
    </>
  );
};

export const LoadingLayout = (): JSX.Element => (
  <LayoutMinimumWithHead meta={{ title: "Kraunama" }} menuLeft={<></>} menuRight={<></>}>
    <Loading />
  </LayoutMinimumWithHead>
);

export const LayoutMinimum = ({ children, sticky, notSticky, menuLeft, menuRight }: Props): JSX.Element => {
  const [stickyHead, setSticky] = useState(false);
  const [show, setShow] = React.useState(false);
  const size = useResponsive(SIZES.XS);
  const onScroll = () => {
    setSticky(window.scrollY > 40);
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  });

  // @ts-ignore
  return (
    <div className={styles.stickyOuter}>
      <div className={classNames(styles.normal, { [styles.sticky]: stickyHead && !notSticky })}>
        <div className={styles.innerNormal}>
          <div className={styles.menuItems}>
            <a
              href="#"
              className={classNames(styles.menuButton, styles.left)}
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </a>
            <div className={styles.left}>{menuLeft ? menuLeft : <Menu label="left" />}</div>
            <div className={styles.right}>{menuRight ? menuRight : <Menu label="right" />}</div>
          </div>
          <Link href="/" aria-label="apuokynė.lt">
            <HorizontalLogo className={styles.svg} />
          </Link>
        </div>

        {size <= SIZES.SM && <MobileMenu show={show} onHide={() => setShow(false)} />}
        {sticky && sticky(stickyHead)}
        <div className={styles.bottom} />
      </div>
      {children}

      <Toaster position="top-center" toastOptions={infoNotification} />
      <AcceptCookies />
    </div>
  );
};

const Menu = ({ label }: { label: string }): JSX.Element => {
  const menu = useMenu("top");
  return (
    <>
      {menu?.menuItems.nodes
        .find((menu) => menu.label === label)
        .childItems.nodes.map((m) => (
          <Link key={m.label} href={m.path} className={styles.items}>
            {m.label}
          </Link>
        ))}
      {label === "right" && <UserPopup />}
      {label === "right" && <TryOnPopup />}
      {label === "right" && <FavoritesPopup />}
    </>
  );
};
