import { useEffect } from "react";
import Router from "next/router";
import useSWR, { KeyedMutator } from "swr";
import { BaseUserFragment } from "./api/types";

type Props = { redirectTo?: string | false; redirectIfFound?: boolean };
export type UserToken = { authToken: string; refreshToken: string; user: BaseUserFragment };

const useUser = ({ redirectTo = false, redirectIfFound = false }: Props = {}): {
  mutateUser: KeyedMutator<UserToken>;
  user: UserToken | undefined;
} => {
  const { data: user, mutate: mutateUser } = useSWR("/api/user");

  useEffect(() => {
    if (!redirectTo || !user) return;

    if ((redirectTo && !redirectIfFound && !user?.isLoggedIn) || (redirectIfFound && user?.isLoggedIn)) {
      void Router.push(redirectTo);
    }
  }, [user, redirectIfFound, redirectTo]);

  return { user, mutateUser };
};

export default useUser;
