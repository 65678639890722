import fetchJson from "../fetchJson";
import { notifyError, notifyInfo } from "../../components/utils/notifications";
import { ERRORS } from "../../components/userLogin/UserLogin";
import { KeyedMutator } from "swr";
import { UserToken } from "../useUser";

export const login = (
  mutateUser: KeyedMutator<UserToken>,
  { username, password }: { username: string; password: string },
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetchJson("/api/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    })
      .then((data) => {
        void mutateUser(data as UserToken, true)
          .then(() => {
            notifyInfo("Jūs sėkmingai prisijungėte");
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      })
      .catch((e) => {
        if (Object.keys(ERRORS).indexOf(e.message) !== -1) {
          notifyError(ERRORS[e.message]);
        } else {
          throw e;
        }
        reject();
      });
  });
};
