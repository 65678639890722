import * as React from "react";
import { useEffect, useState } from "react";
import { ModalDialog } from "../modals/ModalDialog";
import { getStored, store } from "../../lib/util/localStorage";
import Link from "next/link";
import { useRouter } from "next/router";

export const AcceptCookies = (): JSX.Element => {
  const router = useRouter();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!getStored("acceptCookies") && router.asPath !== "/privatumo-politika?cookies");
  }, [setShow, router.asPath]);

  if (!show) {
    return null;
  }

  return (
    <ModalDialog show hideClose={true}>
      Šioje svetainėje naudojame slapukus, kurie pagerina ir Jums pritaiko svetainės funkcijas, padeda analizuoti
      srautą, pritaikyti Jums internete rodomą reklamą. Sutikimą galėsite bet kada atšaukti, o slapukus ištrinti.{" "}
      <Link href="/privatumo-politika?cookies">Skaityti daugiau</Link>
      <hr />
      <div className="text-right">
        <a
          href="#"
          className="btn btn-warning"
          onClick={(e) => {
            e.preventDefault();
            store("acceptCookies", true);
            setShow(false);
          }}
        >
          Sutinku
        </a>
      </div>
    </ModalDialog>
  );
};
