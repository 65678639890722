import * as React from "react";
import { useEffect } from "react";
import { ModalDialog } from "../modals/ModalDialog";
import { useMutation } from "@apollo/client";
import { RegisterUserDocument } from "../../lib/api/types";
import { Loading } from "../loading/Loading";
import { ERRORS, UserLogin } from "./UserLogin";
import styles from "./UserPopup.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import classNames from "classnames";
import { UserRegister } from "./UserRegister";
import { getStored, Store } from "../../lib/util/localStorage";
import { DropdownMenu } from "../menu/DropdownMenu";
import { useRouter } from "next/router";
import { notifyError, notifyInfo } from "../utils/notifications";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { MenuIcon } from "../menu/MenuIcon";
import useUser from "../../lib/useUser";
import fetchJson from "../../lib/fetchJson";
import { login } from "../../lib/user/login";

export const UserPopup = (): JSX.Element => {
  const { user, mutateUser } = useUser({});

  const router = useRouter();
  const [show, setShow] = React.useState(false);
  const [tab, setTab] = React.useState("login");
  const [loading, setLoading] = React.useState(false);

  const [register, { loading: loadingRegister }] = useMutation(RegisterUserDocument);

  useEffect(() => {
    typeof router.query.prisijungti === "string" && setShow(true);
  }, [router.query.prisijungti]);

  if (!getStored(Store.FEATURE_USER)) {
    return null;
  }

  const onSubmit = (data, type: "login" | "register") => {
    if (type === "login") {
      setLoading(true);
      login(mutateUser, data)
        .then(() => setShow(false))
        .finally(() => setLoading(false));
    }

    if (type === "register") {
      register({ variables: data })
        .then(async () => {
          notifyInfo("Jūs sėkmingai užsiregistravote");
          await login(mutateUser, { password: data.password, username: data.email });
          setShow(false);
        })
        .catch((e) => {
          if (Object.keys(ERRORS).indexOf(e.message) !== -1) {
            notifyError(ERRORS[e.message]);
          } else {
            throw e;
          }
        });
    }
  };

  return (
    <>
      {user && (
        <>
          {user.user ? (
            <DropdownMenu
              title="Paskyra"
              onSelect={(event) => {
                switch (event) {
                  case "my-orders":
                    void router.push("/mano/uzsakymai");
                    break;
                  case "my-account":
                    void router.push("/mano/paskyra");
                    break;
                  case "logout":
                    void fetchJson("/api/logout", {}).then(() => {
                      void mutateUser(undefined, true).then(() => {
                        notifyInfo("Jūs sėkmingai atsijungėte");
                        void router.push("/");
                      });
                    });
                    break;
                  default:
                    throw new Error(`Unexpected event "${event}"`);
                }
              }}
            />
          ) : (
            <MenuIcon
              icon={faUser}
              message="Mano paskyra"
              onClick={(e) => {
                e.preventDefault();
                setShow(!show);
              }}
            />
          )}
        </>
      )}
      <ModalDialog transparent show={show} onHide={() => setShow(false)} className={styles.modal}>
        <div className={styles.login}>
          <Loading loading={loading || loadingRegister}>
            <Tabs
              activeKey={tab}
              onSelect={setTab}
              id="modal-register-tabs"
              className={classNames(styles.nav, "mb-3 nav-fill justify-content-center")}
              variant="pills"
            >
              <Tab eventKey="login" title="Prisijungti">
                <div className={styles.tabContent}>
                  <UserLogin onSubmit={(data) => onSubmit(data, "login")} />
                </div>
              </Tab>
              <Tab eventKey="register" title="Registruotis">
                <div className={styles.tabContent}>
                  <UserRegister onGoToLogin={() => setTab("login")} onSubmit={(data) => onSubmit(data, "register")} />
                </div>
              </Tab>
            </Tabs>
          </Loading>
        </div>
      </ModalDialog>
    </>
  );
};
