import * as React from "react";
import styles from "./Loading.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
// min-height: 400px;
export const Loading = ({
  children,
  loading,
  height,
}: {
  children?: React.ReactNode;
  loading: boolean;
  height?: number;
}): JSX.Element => (
  <div
    className={classNames(styles.loading, { [styles.activeLoading]: loading })}
    style={{ minHeight: loading ? height : undefined }}
  >
    {children}
    {loading && (
      <div className={styles.loader}>
        <FontAwesomeIcon icon={faCircleNotch} spin />
      </div>
    )}
  </div>
);

Loading.defaultProps = {
  height: 400,
};

export const LoadingTable = ({
  cols = 1,
  rows = 10,
  style,
}: {
  cols: number;
  rows: number;
  style?: {};
}): JSX.Element => (
  <>
    {new Array(rows).fill(null).map((_, i) => (
      <tr key={i} className={styles.loadingTable}>
        {new Array(cols).fill(null).map((_, i) => (
          <td key={i} style={style} className={styles[`td-${i + 1}`]}>
            <span />
          </td>
        ))}
      </tr>
    ))}
  </>
);

Loading.defaultProps = {
  loading: true,
};
