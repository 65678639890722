import * as React from "react";
import styles from "./OffsetMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bottomTransition from "./transitions/bottom.module.scss";
import backdropTransition from "./transitions/backdrop.module.scss";
import rightTransition from "./transitions/right.module.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { useEffect } from "react";

type Props = {
  children: React.ReactNode;
  show: boolean;
  onHide: () => void;
  type: "bottom" | "right";
  title: React.ReactNode;
};

export const OffsetMenu = ({ type, onHide, show, children, title }: Props): JSX.Element => {
  const onHideInternal = () => {
    onHide();
    document && document.body.classList.remove("hideScrollBar");
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add("hideScrollBar");
    } else {
      document.body.classList.remove("hideScrollBar");
    }
    return () => document.body.classList.remove("hideScrollBar");
  }, [show]);

  const transition = type === "bottom" ? bottomTransition : rightTransition;
  return (
    <>
      <CSSTransition in={show} mountOnEnter={true} timeout={200} classNames={backdropTransition}>
        <div className={styles.backdrop} onClick={onHideInternal} />
      </CSSTransition>
      <CSSTransition in={show} mountOnEnter={true} timeout={200} classNames={transition}>
        <div className={classNames(styles.menu, styles[type])} key="menu">
          <a
            href="#"
            className={styles.closeButton}
            onClick={(e) => {
              e.preventDefault();
              onHideInternal();
            }}
          >
            <FontAwesomeIcon size="2x" icon={faTimes} />
          </a>
          <div className={styles.title}>{title}</div>
          <div className={styles.menuContent}>{children}</div>
        </div>
      </CSSTransition>
    </>
  );
};
