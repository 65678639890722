import * as React from "react";
import styles from "./DropdownMenu.module.scss";
import { Dropdown } from "react-bootstrap";

type Props = {
  title: React.ReactNode;
  onSelect: (eventName: string) => void;
};
export const DropdownMenu = ({ title, onSelect }: Props): JSX.Element => {
  return (
    <Dropdown onSelect={onSelect} className={styles.dropdown}>
      <Dropdown.Toggle as="a">{title}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="my-account">Paskyra</Dropdown.Item>
        <Dropdown.Item eventKey="my-orders">Mano užsakymai</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="logout">Atsijungti</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
