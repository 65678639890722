import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { toast } from "react-hot-toast";

export const errorNotification = {
  style: {
    background: "red",
    color: "#fff",
  },
  icon: <FontAwesomeIcon icon={faExclamationCircle} />,
  duration: 20000,
};

export const infoNotification = {
  icon: <FontAwesomeIcon icon={faCheckCircle} />,
  style: {
    background: "#333",
    color: "#fff",
  },
};

export const notifyInfo = (message: string): void => {
  toast(message);
};

export const notifyError = (message: string): void => {
  toast(
    (t) => (
      <a style={{ cursor: "pointer" }} onClick={() => toast.dismiss(t.id)}>
        {message}
      </a>
    ),
    errorNotification,
  );
};
