import * as React from "react";
import { FormEvent } from "react";
import styles from "./FooterNewsletter.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { MessageContext } from "../context/MessagesContext";
import classNames from "classnames";
import qs from "qs";

export const FooterNewsletter = (): JSX.Element => {
  const { addMessage } = React.useContext(MessageContext);
  const [email, setEmail] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);

  const subscribe = (e: FormEvent): void => {
    e.preventDefault();
    setDisabled(true);
    axios({
      url: "/wp-admin/admin-ajax.php?action=add_list",
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Accept: "application/json, text/javascript, */*",
      },
      data: qs.stringify({
        email: email,
        "addresses[naujienlaiskiai@apuokyne.lt]": "on",
        "mailgun-submission": "1",
      }),
    })
      .then(() => {
        setEmail("");
        addMessage({
          title: "Sėkimingai užsiprenumeravote",
          message: "Sėkimingai užsiprenumeravote mūsų naujienlaiškį",
        });
      })
      .catch(() => {
        addMessage({ title: "Klaida", message: "Atsiprašome, serverio klaida." });
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <div className={styles.newsletter}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} className="text-center">
            <span className={styles.title}>Prenumeruok naujienlaiškį</span>
            <br />
            <span className={styles.titleSmall}>
              Įvesk savo el. paštą ir pirmas sužinok apie mūsų pasiūlymus ir naujienas!
            </span>
          </Col>
          <Col xs={12} md={8} lg={6} className="text-center">
            <form method="post" onSubmit={subscribe}>
              <br />
              <div className="form-group">
                <div className="d-none d-sm-block">
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      placeholder="El. paštas"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button disabled={disabled} className="btn btn-warning" type="submit">
                        Tapk prenumeratoriumi
                      </button>
                    </div>
                  </div>
                </div>
                <div className={classNames("d-grid d-sm-none", styles.inputXs)}>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    placeholder="El. paštas"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button disabled={disabled} className="btn btn-primary" type="submit">
                    Tapk prenumeratoriumi
                  </button>
                </div>
                <div className="invalid-feedback">Neteisingas el. paštas</div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
