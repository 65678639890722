import * as React from "react";
import styles from "./PasswordStrength.module.scss";
import v from "validator";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { passwordStrengthOptions } from "./UserRegister";

export const PasswordStrength = ({
  password,
  children,
  show,
}: {
  password: string;
  show: boolean;
  children: React.ReactElement;
}): JSX.Element => {
  const strength = v.isStrongPassword(password, { ...passwordStrengthOptions, returnScore: true });
  const progress = Math.min(1, (typeof strength === "number" ? strength : 1) / 40) * 100;

  return (
    <OverlayTrigger
      overlay={
        <Tooltip className={styles.tooltip} id="strength">
          <div>
            <ProgressBar style={{ width: "100%" }} now={progress} />
            <ul>
              <li className={classNames({ [styles.marked]: password.length >= passwordStrengthOptions.minLength })}>
                Minimalus skaičius simbolių: {passwordStrengthOptions.minLength}
              </li>
              <li className={classNames({ [styles.marked]: /[a-z]/.test(password) })}>
                Pridėkite bent vieną mažają raidę
              </li>
              <li className={classNames({ [styles.marked]: /[A-Z]/.test(password) })}>
                Pridėkite bent vieną didžiają raidę
              </li>
              <li className={classNames({ [styles.marked]: /[0-9]/.test(password) })}>Pridėkite bent vieną skaičių</li>
              <li className={classNames({ [styles.marked]: password.replace(/[a-zA-Z0-9]/g, "").length })}>
                Pridėkite bent vieną simbolį
              </li>
            </ul>
          </div>
        </Tooltip>
      }
      placement="bottom"
      show={show}
    >
      {children}
    </OverlayTrigger>
  );
};
