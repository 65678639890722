import * as React from "react";
import styles from "./Footer.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { HorizontalLogo } from "../logo/HorizontalLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHeart, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import classNames from "classnames";
import { Coffee } from "../icons/Coffee";
import Link from "next/link";
import { FooterNewsletter } from "../newsletter/FooterNewsletter";

export const Footer = (): JSX.Element => {
  const [animateCoffee, setAnimateCoffee] = React.useState<boolean>(true);

  return (
    <>
      <FooterNewsletter />
      <div className={styles.footer}>
        <Container>
          <Row>
            <Col sm={6} md={3}>
              <strong>PIRKTI</strong>
              <ul>
                <li>
                  <Link href="/akiniai">Visi akiniai</Link>
                </li>
                <li>
                  <Link href="/akiniai/akiniai-moterims">Akiniai moterims</Link>
                </li>
                <li>
                  <Link href="/akiniai/akiniai-vyrams">Akiniai vyrams</Link>
                </li>
              </ul>
            </Col>
            <Col sm={6} md={3}>
              <strong>SUŽINOTI</strong>
              <ul>
                <li>
                  <Link href="/apie-apuokyne">Apie apuokynę</Link>
                </li>
                <li>
                  <Link href="/kaip-pirkti-akinius">Kaip pirkti akinius internetu</Link>
                </li>
              </ul>
            </Col>
            <Col sm={6} md={3}>
              <strong>INFORMACIJA</strong>
              <ul>
                <li>
                  <Link href="/pristatymas-ir-grazinimas">Pristatymas ir grąžinimas</Link>
                </li>
              </ul>
            </Col>
            <Col>
              <strong>KONTAKTAI</strong>
              <ul>
                <li>
                  <a href="mailto:info@apuokyne.lt" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon size="lg" icon={faEnvelope} /> info@apuokyne.lt
                  </a>
                </li>
                <li>
                  <a href="tel:+37061583357">
                    <FontAwesomeIcon size="lg" icon={faPhone} /> +370 615 83357
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div className={styles.footerContainer}>
          <Container>
            <div className={styles.footerSocial}>
              <a
                href="https://www.facebook.com/apuokynesakiniai"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FontAwesomeIcon className={styles.facebook} size="2x" icon={faFacebookSquare} />
              </a>
              <a href="https://www.instagram.com/apuokyne" target="_blank" rel="noreferrer" aria-label="Instagram">
                <FontAwesomeIcon color={"red"} className={styles.instagram} size="2x" icon={faInstagramSquare} />
              </a>
            </div>
            <div className={styles.left}>Copyright &copy; Apuokyne.lt {new Date().getFullYear()}</div>
            <div className={styles.text}>
              <HorizontalLogo svgStyle={{ height: 40, width: 120 }} />
              <br />
            </div>
          </Container>
        </div>
        <div className={classNames(styles.footerContainer, styles.footerLast)}>
          <Container className={styles.container}>
            <span>
              Sukurta pasitelkiant
              <span className={styles.heart}>
                <span>
                  <FontAwesomeIcon icon={faHeart} />
                </span>
              </span>
              ir šiek tiek{" "}
              <span onMouseEnter={() => setAnimateCoffee(false)} onMouseLeave={() => setAnimateCoffee(true)}>
                <Coffee disableAnimate={animateCoffee} />
              </span>
            </span>
            <div className={styles.footerLinksOnRight}>
              <Link href="/taisykles">Pirkimo taisyklės</Link>
              <Link href="/privatumo-politika">Privatumo politika</Link>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
