import * as React from "react";
import styles from "./BorderBox.module.scss";
import classNames from "classnames";

export const BorderBox = ({
  children,
  title,
  style,
  radius,
  className,
  variant,
  active,
}: {
  children: React.ReactNode;
  title?: string;
  className?: string;
  radius?: "sm";
  style?: React.CSSProperties;
  variant?: "light" | "info" | "success";
  active?: boolean;
}): JSX.Element => {
  return (
    <div
      className={classNames(className, styles[radius], styles.borderBox, {
        [styles[variant]]: variant,
        [styles.active]: active,
      })}
      style={style}
    >
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );
};
