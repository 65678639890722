import * as React from "react";
import { useState } from "react";
import styles from "./UserLogin.module.scss";
import { Col, Row } from "react-bootstrap";
import Link from "next/link";

type Props = {
  onSubmit: (data: { username: string; password: string }) => void;
  error?: string;
};

export const ERRORS = {
  empty_username: "Tuščias vartotojo vardas",
  empty_password: "Tuščias slaptažodis",
  invalid_username: "Neteisingas vartotojo vardas arba slaptažodis",
  invalid_email: "Neteisingas vartotojo vardas arba slaptažodis",
  incorrect_password: "Neteisingas vartotojo vardas arba slaptažodis",
};

export const UserLogin = ({ onSubmit }: Props): JSX.Element => {
  const [data, setData] = useState({ username: "", password: "" });
  const inputProps = (name: keyof typeof data) => ({
    value: data[name],
    id: name,
    name: name,
    onChange: (e) => setData({ ...data, [name]: e.target.value }),
    className: "form-control",
  });

  const onSubmitInternal = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(data);
  };

  return (
    <form onSubmit={onSubmitInternal} className={styles.form}>
      <div className="d-grid gap-2">
        <div className="form-group">
          <label htmlFor="username">El. paštas:</label>
          <input autoFocus type="text" {...inputProps("username")} />
        </div>
        <div className="form-group">
          <label htmlFor="password">Slaptažodis:</label>
          <input type="password" {...inputProps("password")} className="form-control" />
        </div>
      </div>
      <hr />
      <Row className="no-gutters">
        <Col className="d-grid">
          <button type="submit" className="btn btn-success">
            Prisijungti
          </button>
        </Col>
        <Col className="d-grid">
          <Link href="/pamirsau-slaptazodi" className="btn btn-light">
            Pamiršote slaptažodį?
          </Link>
        </Col>
      </Row>
    </form>
  );
};
