import * as React from "react";
import styles from "./FavoritePopup.module.scss";
import { AppContext } from "../context/AppContext";
import { GetProductsByIdDocument, GetProductsByIdLazyQueryHookResult } from "../../lib/api/types";
import { useLazyQuery } from "@apollo/client";
import { FavoriteCard } from "./FavoriteCard";
import { faCircleNotch, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OffsetMenu } from "../menu/OffsetMenu";
import classNames from "classnames";
import { MenuIcon } from "../menu/MenuIcon";

export const FavoritesPopup = (): JSX.Element => {
  const { favorites, setFavorites } = React.useContext(AppContext);
  const [removed, setRemove] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [getProducts, { loading, data }]: GetProductsByIdLazyQueryHookResult = useLazyQuery(
    GetProductsByIdDocument,
    {},
  );

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShow(!show);
    if (!show && favorites.length > 0) {
      getProducts({ variables: { id: favorites } });
    }
  };
  const onRemove = (product) => {
    setRemove([...removed, product.databaseId]);
    setFavorites(favorites.filter((f) => f !== product.databaseId));
  };

  const products =
    !loading && data?.products?.edges && data.products.edges.filter((p) => removed.indexOf(p.node.databaseId) === -1);

  return (
    <span>
      <MenuIcon
        className={classNames(styles.favorite, { [styles.love]: favorites.length })}
        onClick={onClick}
        icon={faHeart}
        message="Mėgstami"
        count={favorites.length > 0 ? favorites.length : undefined}
      />

      <OffsetMenu show={show} onHide={() => setShow(false)} type="right" title="Mėgstami">
        {favorites.length === 0 ? (
          <div>
            Papildyk šį sąrašą spausdamas <FontAwesomeIcon className={styles.heart} icon={faHeart} /> <br /> Ją rasi
            prie kiekvienos prekės!
          </div>
        ) : (
          <>
            {loading && (
              <div>
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            )}
            {!loading &&
              products &&
              products.map((p) => <FavoriteCard key={p.node.id} onRemoved={onRemove} product={p.node} />)}
          </>
        )}
      </OffsetMenu>
    </span>
  );
};
