import { useEffect, useState } from "react";

type Size = number;

export const SIZES = {
  XS: 0,
  SM: 1,
  MD: 2,
  LG: 3,
  XL: 4,
};

const sizes: Array<{ key: Size; min: number }> = [
  { key: SIZES.XL, min: 1200 },
  { key: SIZES.LG, min: 992 },
  { key: SIZES.MD, min: 768 },
  { key: SIZES.SM, min: 576 },
  { key: SIZES.XS, min: 0 },
];

export const useResponsive = (initialState: Size = SIZES.LG): Size => {
  const [size, setSize] = useState<Size>(initialState);

  useEffect(() => {
    const onWindowResize = () => {
      if (typeof window !== "object") {
        return;
      }

      sizes.some(({ key, min }: { key: Size; min: number }) => {
        if (window.matchMedia(`screen and (min-width: ${min}px)`).matches) {
          if (size !== key) {
            setSize(key);
          }
          return true;
        }
        return false;
      });
    };

    if (typeof window === "object") {
      window.addEventListener("resize", onWindowResize);
    }

    onWindowResize();

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  });

  return size;
};
