import {
  BaseExternalProductFragment,
  BaseGroupProductFragment,
  BaseProductCategoryFragment,
  BaseProductVariationFragment,
  BaseSimpleProductFragment,
  BaseVariableProductFragment,
} from "../api/types";
import { BaseProductType } from "../api/products";
import { SearchQueryType } from "../../components/router/search";
import { isAttribute, convertAttributeKey } from "../../components/attributes/Attributes";

const and = "-ir-";
const category = "akiniai";

export const urlForPost = (slug: string): string => `/${slug}`;

export const searchLinkFromQuery = (query: SearchQueryType): string => {
  let url = (Object.keys(query) as Array<keyof SearchQueryType>)
    .filter(isAttribute)
    .filter((key) => query[key].length > 0)
    .map((key) => `${convertAttributeKey(key).url || key}-${[...query[key]].sort().join(and)}`)
    .join("/");
  if (query.categories.length > 0) {
    url = `${category}-${[...query.categories].sort().join(and)}/${url}`;
  }

  url = `/akiniai/${url}`;
  if (url.endsWith("/")) {
    url = url.slice(0, url.length - 1);
  }

  if (query.page > 1) {
    url = `${url}/${query.page}`;
  }

  return url;
};

export const categoryLink = (category: BaseProductCategoryFragment, page = 1): string =>
  `/akiniai/${category.slug}/${page > 1 ? page : ""}`.replace(/\/$/, "");

export const productLink = (product: BaseProductType): string => `/${product.slug}`;

export const checkoutLinkSimple = (
  product: BaseSimpleProductFragment | BaseExternalProductFragment | BaseGroupProductFragment,
  lens: BaseSimpleProductFragment,
): string => `/${product.slug}/${lens.slug}/pirkti`;

export const checkoutLinkVariable = (
  product: BaseVariableProductFragment,
  variant: BaseProductVariationFragment,
  lens: BaseSimpleProductFragment,
): string => `/${product.slug}/${lens.slug}/${variant.id}/pirkti`;
