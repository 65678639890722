import rosetta from "rosetta";

export const i18n = rosetta({
  lt: {
    components: {
      card: {
        prices({ price }: { price: string }) {
          return `Nuo ${price}`;
        },
      },
      tryOnPopup: {
        title: "Pasimatavimui namuose",
        beforeHeading: "Papildyk šį sąrašą spausdamas ant:",
        heading: "Pasimatuok namuose",
        afterHeading: "Ją rasi prie kiekvienos prekės puslapyje!",
        checkout: "Pasimatuok namuose",
      },
    },
    addToTryOn: {
      notification: {
        tooMuch: {
          title: "Atsiprašome, pasiekėte limitą",
          message: "Jūs pridėjote maksimalų skaičių akinių",
        },
      },
    },
    forgotPassword: {
      title: "Pamiršau slaptažodį",
      button: {
        submit: "Priminti",
      },
      notification: {
        invalidEmail: "Neteisingas el pašto adresas vardas",
        success: "Jūms buvo išsiūstas el. paštas su tolesniais nurodymais",
      },
    },
    form: {
      email: {
        name: "El. paštas",
      },
    },
  },
});

i18n.locale("lt");
