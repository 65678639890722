import * as React from "react";
import Head from "next/head";
import { LayoutMinimum } from "./layout/Normal";
import { Footer } from "./layout/Footer";

export type MetaType = {
  meta: {
    title: string;
    description?: string;
    noindex?: boolean;
    markup?: Object;
  };
};

type Props = MetaType & {
  children: React.ReactNode;
  sticky?: (sticky: boolean) => JSX.Element;
};

export const layoutTopHeight = (): number => {
  return 50;
};

export const TitleAndDescription = ({ meta }: MetaType): JSX.Element => (
  <Head>
    <meta charSet="UTF-8" />
    <title>{meta.title ? meta.title : "apuokynė"}</title>
    {meta.description && <meta name="description" content={meta.description} />}
    {meta.noindex ? <meta name="robots" content="noindex" /> : <meta name="robots" content="index, follow" />}
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="theme-color" content="#ffffff" />
    <meta name="apple-mobile-web-app-status-bar-style" content="#ffffff" />
    <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
    <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
    <link rel="apple-touch-icon" href="/apple-touch.png" />
    <link rel="manifest" href={`/manifest.json?${process.env.NEXT_PUBLIC_VERSION}`} />
    <link rel="apple-touch-startup-image" href="/apple_splash_2048.png" sizes="2048x2732" />
    <link rel="apple-touch-startup-image" href="/apple_splash_1668.png" sizes="1668x2224" />
    <link rel="apple-touch-startup-image" href="/apple_splash_1536.png" sizes="1536x2048" />
    <link rel="apple-touch-startup-image" href="/apple_splash_1242.png" sizes="1242x2208" />
    <link rel="apple-touch-startup-image" href="/apple_splash_1125.png" sizes="1125x2436" />
    <link rel="apple-touch-startup-image" href="/apple_splash_750.png" sizes="750x1334" />
    <link rel="apple-touch-startup-image" href="/apple_splash_640.png" sizes="640x1136" />
    <meta name="verify-paysera" content="731bc072138e893c856a5f1b053bab41" />
    <meta name="facebook-domain-verification" content="4ku79lgcbdxav1vavypxqddohktszf" />
    {meta.markup && <script type="application/ld+json">{JSON.stringify(meta.markup)}</script>}
  </Head>
);

export const Layout = ({ meta, children, sticky }: Props): JSX.Element => {
  return (
    <>
      <TitleAndDescription meta={meta} />
      <LayoutMinimum sticky={sticky}>{children}</LayoutMinimum>

      <Footer />
    </>
  );
};
