import * as React from "react";
import styles from "./FavoriteCard.module.scss";
import { BaseProductType } from "../../lib/api/products";
import { productLink } from "../../lib/util/links";
import Link from "next/link";
import Image from "next/image";
import { BorderBox } from "../layout/BorderBox";

type Props = {
  product: BaseProductType;
  onRemoved: (product: BaseProductType, variantId?: number) => void;
  variantId?: number;
};

export const FavoriteCard = ({ product, onRemoved, variantId }: Props): JSX.Element => {
  let image = product.image;
  const name = product.name;
  let variant = null;
  let attributes = [];
  if (variantId && product.__typename === "VariableProduct") {
    variant = product.variations.nodes.find(({ databaseId }) => databaseId === variantId);
    attributes = variant.attributes.nodes;
    image = variant?.image ? variant.image : image;
    // name = variant?.name ? variant.name : name;
  }

  return (
    <BorderBox className={styles.borderBox} variant="light">
      <div className={styles.card}>
        <div className={styles.img}>
          <Link href={productLink(product)}>
            <Image width={100} height={50} style={{ objectFit: "contain" }} src={image.sourceUrl} alt={image.altText} />
          </Link>
        </div>
        <div className={styles.info}>
          <Link href={productLink(product)}>{name}</Link>
          {attributes.map((a) => (
            <div className={styles.remove} key={a.databaseId}>
              {a.label}: {a.value}
            </div>
          ))}
          <div className={styles.remove}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onRemoved(product, variantId);
              }}
            >
              Išimti iš mėgstamų
            </a>
          </div>
        </div>
      </div>
    </BorderBox>
  );
};
