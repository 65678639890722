import * as React from "react";
import styles from "./MenuIcon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

type Props = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => unknown;
  className?: string;
  icon: IconProp;
  message: string;
  count?: number;
};
export const MenuIcon = ({ onClick, count, className, icon, message }: Props): JSX.Element => {
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip id="menu">{message}</Tooltip>}>
      <a className={classNames(styles.link, className)} onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
        {count && (
          <Badge className={styles.count} bg="warning" pill>
            {count}
          </Badge>
        )}
      </a>
    </OverlayTrigger>
  );
};
