import * as React from "react";
import styles from "./Coffee.module.scss";
import Cup from "./cup.svg";
import Squiggle from "./squiggle.svg";
import classNames from "classnames";

export const Coffee = ({ disableAnimate }: { disableAnimate: boolean }): JSX.Element => {
  return (
    <div className={styles.aligner}>
      <div className={classNames(styles.alignerItem, styles.coffeeContainer)}>
        <div style={{ visibility: disableAnimate === true ? "hidden" : "visible" }} className={styles.steamContainer}>
          <div className={classNames(styles.squiggleContainer, styles.squiggleContainer1)}>
            <div className={styles.squiggle}>
              <Squiggle />
            </div>
          </div>
          <div className={classNames(styles.squiggleContainer, styles.squiggleContainer2)}>
            <div className={styles.squiggle}>
              <Squiggle />
            </div>
          </div>
          <div className={classNames(styles.squiggleContainer, styles.squiggleContainer3)}>
            <div className={styles.squiggle}>
              <Squiggle />
            </div>
          </div>
        </div>
        <div className={styles.coffeeCupContainer}>
          <Cup
            className={styles.coffeeCup}
            stroke={disableAnimate ? "#AAAAAA" : "#b18461"}
            fill={disableAnimate ? "#AAAAAA" : "#b18461"}
          />
        </div>
      </div>
    </div>
  );
};
