import * as React from "react";
import styles from "./FavoritePopup.module.scss";
import { Button } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import { GetProductsByIdDocument, GetProductsByIdLazyQueryHookResult, GetProductsByIdQuery } from "../../lib/api/types";
import { useLazyQuery } from "@apollo/client";
import { FavoriteCard } from "./FavoriteCard";
import { faBox, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OffsetMenu } from "../menu/OffsetMenu";
import classNames from "classnames";
import { MenuIcon } from "../menu/MenuIcon";
import { BaseProductType } from "../../lib/api/products";
import fetchJson from "../../lib/fetchJson";
import { useRouter } from "next/router";
import { TRY_ON_LIMIT } from "../../lib/util/constants";
import { notifyError } from "../utils/notifications";
import { i18n } from "../../lib/util/i18n";

const tryOnVariants = (
  products: GetProductsByIdQuery,
  tryOn: Array<{ productId: number; variantId?: number }>,
): { product: { node?: BaseProductType }; variantId?: number }[] =>
  tryOn.map(({ productId, variantId }) => {
    const product = products.products.edges.find((p) => p.node.databaseId === productId);
    return {
      product: product,
      variantId: product && product.node.__typename === "VariableProduct" && variantId ? variantId : undefined,
    };
  });

export const TryOnPopup = (): JSX.Element => {
  const router = useRouter();
  const { tryOn, setTryOn } = React.useContext(AppContext);
  const [removed, setRemove] = React.useState([]);
  const [loadingTryOn, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [getProducts, { loading, data }]: GetProductsByIdLazyQueryHookResult = useLazyQuery(
    GetProductsByIdDocument,
    {},
  );

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShow(!show);
    if (!show && tryOn.length > 0) {
      getProducts({ variables: { id: tryOn.map((t) => t.productId) } });
    }
  };

  const onRemove = (product, variantId) => {
    setRemove([...removed, product.databaseId]);
    setTryOn(tryOn.filter((f) => f.productId !== product.databaseId || f.variantId !== variantId));
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      await fetchJson("/api/addToTryOn", { body: JSON.stringify({ products: tryOn }) });
      void router.push("/apmokejimas");
    } catch (e) {
      setLoading(false);
      notifyError(e.message);
      throw e;
    }
  };

  return (
    <span>
      <MenuIcon
        className={classNames(styles.favorite, { [styles.love]: tryOn.length })}
        onClick={onClick}
        icon={faBox}
        message={i18n.t("components.tryOnPopup.title")}
        count={tryOn.length > 0 ? tryOn.length : undefined}
      />

      <OffsetMenu show={show} onHide={() => setShow(false)} type="right" title={i18n.t("components.tryOnPopup.title")}>
        {tryOn.length === 0 ? (
          <div>
            {i18n.t("components.tryOnPopup.beforeHeading")} <br />
            <FontAwesomeIcon className={styles.heart} icon={faBox} /> {i18n.t("components.tryOnPopup.heading")} <br />
            {i18n.t("components.tryOnPopup.afterHeading")}
          </div>
        ) : (
          <>
            {loading && (
              <div>
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            )}
            {!loading &&
              data?.products &&
              tryOnVariants(data, tryOn)
                .filter((p) => p.product)
                .map((p) => (
                  <FavoriteCard
                    key={`${p.product.node.id}-${p.variantId}`}
                    onRemoved={onRemove}
                    product={p.product.node}
                    variantId={p.variantId}
                  />
                ))}
          </>
        )}
        {tryOn.length !== 0 && (
          <Button
            variant="outline-success"
            style={{ paddingTop: 10 }}
            disabled={loadingTryOn || tryOn.length < 1 || tryOn.length > TRY_ON_LIMIT}
            onClick={handleClick}
          >
            <FontAwesomeIcon spin={loadingTryOn} fixedWidth icon={loadingTryOn ? faCircleNotch : faBox} /> &nbsp;
            {i18n.t("components.tryOnPopup.checkout")}
          </Button>
        )}
      </OffsetMenu>
    </span>
  );
};
