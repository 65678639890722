import * as React from "react";
import { useState } from "react";
import styles from "./UserLogin.module.scss";
import { Col, Row } from "react-bootstrap";
import v from "validator";
import classNames from "classnames";
import { PasswordStrength } from "./PasswordStrength";

export const passwordStrengthOptions = {
  minLength: 8,
  minLowercase: 1,
  minNumbers: 1,
  minSymbols: 1,
  minUppercase: 1,
};

type Props = {
  onGoToLogin: () => void;
  onSubmit: (data: {
    email: string;
    password: string;
    passwordRepeat: string;
    firstName: string;
    lastName: string;
    phone: string;
  }) => void;
};

export const UserRegister = ({ onSubmit, onGoToLogin }: Props): JSX.Element => {
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    email: "",
    password: "",
    passwordRepeat: "",
    firstName: "",
    lastName: "",
    phone: "",
  });

  const inputProps = (name: keyof typeof data) => ({
    value: data[name],
    name: name,
    onChange: (e) => setData({ ...data, [name]: e.target.value }),
    onFocus: () => setErrors({ ...errors, [name]: null }),
    className: classNames("form-control", { "is-invalid": errors[name] === false, "is-valid": validation[name]() }),
  });

  const validation = {
    firstName: () => v.isLength(data.firstName, { min: 3, max: 30 }),
    lastName: () => v.isLength(data.lastName, { min: 3, max: 30 }),
    email: () => v.isEmail(data.email),
    phone: () => v.isMobilePhone("+370" + data.phone, "lt-LT"),
    password: () => v.isStrongPassword(data.password, passwordStrengthOptions),
    passwordRepeat: () =>
      data.passwordRepeat === data.password && data.password.length >= passwordStrengthOptions.minLength,
  };

  const onSubmitInternal = (event: React.FormEvent) => {
    event.preventDefault();
    const err = {};
    const valid = !(Object.keys(validation) as Array<keyof typeof data>)
      .map((key) => {
        err[key] = validation[key]();
        return key;
      })
      .some((key) => !err[key]);
    setErrors(err);
    if (valid) {
      onSubmit(data);
    }
  };

  return (
    <form onSubmit={onSubmitInternal} className={styles.form}>
      <div className="d-grid gap-2">
        <div className="form-group">
          <label htmlFor="username">El. paštas:</label>
          <input autoFocus type="text" {...inputProps("email")} />
          <div className="invalid-feedback">Neteisingas el. paštas</div>
        </div>
        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="passwordRegister">Slaptažodis:</label>
              <PasswordStrength password={data.password} show={passwordFocus}>
                <input
                  id="passwordRegister"
                  type="password"
                  {...inputProps("password")}
                  onFocus={() => setPasswordFocus(true)}
                  onBlur={() => setPasswordFocus(false)}
                />
              </PasswordStrength>
              <div className="invalid-feedback">Neteisingas slaptažodis</div>
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label htmlFor="passwordRepeat">Pakartokite slaptažodį:</label>
              <input type="password" {...inputProps("passwordRepeat")} />
              <div className="invalid-feedback">Neteisingas slaptažodžio pakartojimas</div>
            </div>
          </Col>
        </Row>
        <div className="form-group">
          <label htmlFor="username">Vardas:</label>
          <input type="text" {...inputProps("firstName")} />
          <div className="invalid-feedback">Neteisingas vardas</div>
        </div>
        <div className="form-group">
          <label htmlFor="username">Pavardė:</label>
          <input type="text" {...inputProps("lastName")} />
          <div className="invalid-feedback">Neteisinga pavardė</div>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Tel. nr.:</label>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">+370</span>
            </div>
            <input
              type="text"
              className={classNames("form-control", { "is-invalid": validation["phone"]() })}
              {...inputProps("phone")}
            />
            <div className="invalid-feedback">Neteisingas telefonas</div>
          </div>
        </div>
      </div>
      <hr />
      <Row className="no-gutters">
        <Col className="d-grid">
          <button type="submit" className="btn btn-success">
            Registruotis
          </button>
        </Col>
        <Col className="d-grid">
          <a
            className="btn btn-light"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onGoToLogin();
            }}
          >
            Jau turite prisijungimą?
          </a>
        </Col>
      </Row>
    </form>
  );
};
