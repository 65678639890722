import * as React from "react";
import { MenuContext } from "../context/AppContext";
import { BaseMenuFragment } from "../../lib/api/types";

export const useMenu = (which: string): BaseMenuFragment | undefined => {
  const nodes = React.useContext(MenuContext);
  if (!nodes || !nodes.menus) {
    return undefined;
  }
  return nodes.menus.nodes.find((menu) => menu.name === which);
};
