import { ProductAttribute } from "../../lib/api/types";

export type AttributesType = "PaForma" | "PaMedziaga" | "PaSpalva";
export type AttributesStringType = "forma" | "medziaga" | "spalva";
export const ATTR_THINING = "ploninima";

const attributes = {
  PaForma: {
    url: "forma",
    human: "Forma",
    graph: "paForma",
    graphMultiple: "paFormas",
  },
  PaMedziaga: {
    url: "medziaga",
    human: "Medžiaga",
    graph: "paMedziaga",
    graphMultiple: "paMedziagas",
  },
  PaSpalva: {
    url: "spalva",
    human: "Spalva",
    graph: "paSpalva",
    graphMultiple: "paSpalvas",
  },
};

export const ProductAttributes = Object.keys(attributes) as Array<AttributesType>;

export const isAttribute = (s: string | undefined): s is AttributesType => {
  return ProductAttributes.indexOf(s as AttributesType) !== -1;
};

export const convertAttributeKey = (key: AttributesType): typeof attributes[AttributesType] => {
  return attributes[key];
};

export const getAttribute = <T extends Pick<ProductAttribute, "name">>(attrs: T[], key: string): T | undefined =>
  attrs.find((a) => a.name.toLowerCase() === key);
